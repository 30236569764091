import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../../ui/noData/NoData';
import ChartAdditionalStats from '../../ChartAdditionalStats';
import { BarChart } from '@mui/x-charts/BarChart';
import { addedCartSVG } from '../../../../lib/allCustomSVGs';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

type Props = {
  apiResponse: any;
};

const SingleDeviceConversionRateBarChart = ({ apiResponse }: Props) => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState<any[]>([]);
  const [additionalStats, setAdditionalStats] = useState<any>({
    total_device_carts: 0,
  });
  const [loading, setLoading] = useState<
    'pending' | 'success' | 'error' | 'null'
  >('pending');

  const getConversionRateData = async (res: any) => {
    setLoading('pending');
    if (res?.type === 'singleDevice' && res?.data) {
      let newChartData = [];
      let count = 0;
      let extraStats = { ...additionalStats };

      if (Object.keys(res?.data)?.length === 0) {
        setLoading('null');
        return;
      }
      for (const key in res?.data) {
        if (key === 'total_device_carts') {
          extraStats.total_device_carts = res?.data[key] || 0;
        } else {
          newChartData.push({
            key: key, // Change 'label' to 'key'
            value: res?.data[key]?.conversion_rate || 0,
          });
        }

        count += 1;
      }

      // Set chart data once
      setAdditionalStats(extraStats);
      setChartData(newChartData);
      setLoading('success');
    }
  };

  useEffect(() => {
    if (apiResponse) {
      getConversionRateData(apiResponse);
    }
  }, [apiResponse]);

  const chartSetting = {
    yAxis: [
      {
        label: 'Conversion Rate (%)',
      },
    ],
  };

  const valueFormatter = (value: number | null) => `${value}%`;

  const data = {
    data: chartData,
    valueFormatter,
  };

  const AdditionalStatsData = [
    {
      label: t('TotalDeviceCarts'),
      value: additionalStats?.total_device_carts,
      fillColor: '#84b6d8',
      svg: addedCartSVG,
    },
  ];

  return (
    <>
      {loading === 'pending' && <LoadingSpinner />}

      {loading === 'null' && (
        <NoData
          title={t('No data available')}
          subTitle={t('NeedMoreInteractionsToGenerateStats')}
        />
      )}
      {loading === 'success' && (
        <>
          <div className='chart__left--content-chart'>
            <div
              style={{ width: '100%' }}
              className='chart__left--content-chart-pie'
            >
              <BarChart
                dataset={chartData}
                xAxis={[{ scaleType: 'band', dataKey: 'key' }]}
                series={[{ dataKey: 'value', valueFormatter }]}
                colors={['#8884d8']}
                {...chartSetting}
                sx={(theme) => ({
                  [`.${axisClasses.root}`]: {
                    [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                      stroke: '#8b909a',
                      strokeWidth: 3,
                    },
                    [`.${axisClasses.tickLabel}`]: {
                      fill: '#8b909a',
                    },
                  },
                  [`.${axisClasses.left} .${axisClasses.label}`]: {
                    fill: '#8b909a',
                    transform: 'translate(-10px, 0)',
                  },
                  border: '1px solid #8b909a25',
                  backgroundImage:
                    'linear-gradient(#8b909a25 1px, transparent 1px), linear-gradient(90deg, #8b909a25 1px, transparent 1px)',
                  backgroundSize: '50px 50px',
                  backgroundPosition: '0px 0px, 0px 0px',
                  ...theme.applyStyles('dark', {
                    borderColor: '#8b909a25',
                    backgroundImage:
                      'linear-gradient(#8b909a25 1px, transparent 1px), linear-gradient(90deg, #8b909a25 1px, transparent 1px)',
                  }),
                })}
              />
            </div>
          </div>
          <ChartAdditionalStats stats={AdditionalStatsData} />
        </>
      )}
    </>
  );
};

export default SingleDeviceConversionRateBarChart;
