import CustomSVGs from '../../ui/customSVGs/CustomSVGs';
import '../../styles/components/charts/chart.scss';

type StatItem = {
  label: string;
  value: number;
  fillColor: string;
  svg: React.ReactNode;
};

type StatsProps = {
  stats: StatItem[];
};

const ChartAdditionalStats: React.FC<StatsProps> = ({ stats }) => {
  return (
    <div className='chart__left--content-stats'>
      {stats?.map((stat, index) => (
        <div className='chart__left--content-stats-item' key={index}>
          <CustomSVGs fillColor={stat?.fillColor} svg={stat?.svg} />
          <div className='chart__left--content-stats-item-text'>
            <span>{stat?.label}</span>
            <span>{stat?.value}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChartAdditionalStats;
