import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SubPageContentHolder from '../../components/subPageContentHolder/SubPageContentHolder';
import SubPageInnerContentHolder from '../../components/subPageContentHolder/SubPageInnerContentHolder';
import DashboardTab from './DashboardTab';
import get_all_shop_stats_api_call from '../../lib/api/dashboard/get_all_shop_stats_api_call';
import NoData from '../../ui/noData/NoData';
import LoadingSpinner from '../../ui/loadingSpinner/LoadingSpinner';
import PageWrapperMain from '../../components/page-wrappers/PageWrapperMain';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAiTrackerAccessToken,
  setAiTrackerAPIKey,
  setAiTrackerConfiguredTimestamp,
} from '../../store/reducers/userSlice';
import AiCheckoutTrackingStats from './stats/AiCheckoutTrackingStats';
import addDaysToDate from '../../lib/helper-functions/addDaysToDate';
import { DAYS_TO_ADD } from '../../lib/constants/abstracts';
import get_checkout_tracking_api_call from '../../lib/api/integration/get_checkout_tracking_api_call';
import get_access_token_by_integration_key_api_call from '../../lib/api/ai-tracker/get_access_token_by_integration_key_api_call';
import { RootState } from '../../store/store';

interface ApiDataType {
  api_key: string;
  [key: string]: any;
}

const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [statistics, setStatistics] = useState<any>({});
  const [isTimesUp, setIsTimesUp] = useState<boolean>(false);
  const [key, setKey] = useState<string>('');
  const [isTrackable, setIsTrackable] = useState<boolean>(false);
  const [showTabs, setShowTabs] = useState<boolean>(false);
  const stats = useSelector((state: RootState) => state.user?.stats);
  const [loading, setLoading] = useState<
    'pending' | 'success' | 'error' | 'null'
  >('pending');

  useEffect(() => {
    getStatistics();
    getAvailableTrackingData();
  }, []);

  // Check if the tracking time is up
  useEffect(() => {
    if (statistics?.additional_stats?.tracker_configured_timestamp) {
      const dateTimeISOString =
        statistics?.additional_stats?.tracker_configured_timestamp;
      const futureDate = addDaysToDate(
        dateTimeISOString ? dateTimeISOString : '',
        DAYS_TO_ADD
      );

      const calculateTimeLeft = () => {
        const now = new Date().getTime();
        const targetTime = futureDate.getTime();
        const difference = targetTime - now;

        if (difference <= 0) {
          setIsTimesUp(true);
        }
      };

      calculateTimeLeft();
    }
  }, [statistics]);

  //get basic dashboard statistics
  const getStatistics = async () => {
    setLoading('pending');
    try {
      const res = await get_all_shop_stats_api_call();
      if (res) {
        setStatistics(res);
        dispatch(
          setAiTrackerConfiguredTimestamp(
            res?.additional_stats?.tracker_configured_timestamp
              ? res?.additional_stats?.tracker_configured_timestamp
              : ''
          )
        );
        setLoading('success');
        return;
      } else {
        setLoading('null');
      }
    } catch (error) {
      console.error('Error fetching tracking data:', error);
      setLoading('error');
    }
  };

  /* --------------------------------------------- */
  //function to get tacker API key
  const getAvailableTrackingData = async () => {
    setLoading('pending');
    try {
      const res1: ApiDataType[] = await get_checkout_tracking_api_call();

      if (!res1 || res1.length === 0) {
        setIsTrackable(false);
      } else {
        if (
          res1[res1.length - 1]?.configuration &&
          JSON.stringify(res1[res1.length - 1].configuration) ===
            JSON.stringify({ key: 'value' })
        ) {
          setIsTrackable(false);
        } else {
          setIsTrackable(true);
        }

        setKey(res1[res1.length - 1]?.api_key || '');
        dispatch(setAiTrackerAPIKey(res1[res1.length - 1]?.api_key || ''));
      }
    } catch (error) {
      console.error('Error fetching tracking data:', error);
      setLoading('error');
    }
  };

  //function to get access token
  const getTrackerAccessToken = async (apiKey: string) => {
    //call to get API key
    if (stats.ai_tracker_access_token === '') {
      const res2 = await get_access_token_by_integration_key_api_call(apiKey);

      if (res2?.access_token && typeof res2.access_token === 'string') {
        dispatch(setAiTrackerAccessToken(res2.access_token || ''));
        if (res2.access_token) {
          setLoading('success');
          return;
        }
      }
    }
  };

  useEffect(() => {
    if (
      isTimesUp &&
      key !== '' &&
      statistics?.additional_stats?.is_autofill_enabled
    ) {
      setShowTabs(false);
      //function to get access token
      getTrackerAccessToken(key);
    } else if (
      isTimesUp &&
      key !== '' &&
      !statistics?.additional_stats?.is_autofill_enabled
    ) {
      setShowTabs(true);
      //function to get access token
      getTrackerAccessToken(key);
    } else {
      setShowTabs(true);
    }
  }, [isTimesUp, key, statistics]);
  /* --------------------------------------------- */

  return (
    <PageWrapperMain>
      <SubPageContentHolder>
        <SubPageInnerContentHolder
          title={t('Dashboard')}
          subTitle={t('DashboardSubTitle')}
        >
          {loading === 'pending' && <LoadingSpinner />}
          {loading === 'error' && (
            <NoData
              title={t('ErrorFetchingData')}
              subTitle={t('SomethingWentWrong-msg')}
            />
          )}
          {loading === 'success' && (
            <>
              {showTabs && (
                <DashboardTab stats={statistics} isTimesUp={isTimesUp} />
              )}
              <AiCheckoutTrackingStats
                isTrackable={isTrackable}
                isTimesUp={isTimesUp}
              />
            </>
          )}
        </SubPageInnerContentHolder>
      </SubPageContentHolder>
    </PageWrapperMain>
  );
};

export default Dashboard;
