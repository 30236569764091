import '../../styles/components/charts/chart.scss';
import NormalSelectInput from '../../ui/inputs/NormalSelectInput';
import { useTranslation } from 'react-i18next';
import CustomSVGs from '../../ui/customSVGs/CustomSVGs';
import { arrowHeadLeftSVG, filterSVG } from '../../lib/allCustomSVGs';
import { useState } from 'react';

type Props = {
  loading?: string;
  children: React.ReactNode;
  groupBy?: string;
  setGroupBy?: React.Dispatch<React.SetStateAction<string>>;
  timeFilter?: string;
  setTimeFilter?: React.Dispatch<React.SetStateAction<string>>;
  groupByOptionsArr?: { OptionValue: string; OptionName: string }[];
  timeFilterOptionsArr?: { OptionValue: string; OptionName: string }[];
  isBackButtonVisible?: boolean;
  onBackButtonClick?: () => void;
  title: string;
  comment: string;
};

const ChartWithConfig = ({
  loading,
  children,
  groupBy,
  setGroupBy,
  groupByOptionsArr,
  timeFilter,
  setTimeFilter,
  timeFilterOptionsArr,
  isBackButtonVisible,
  onBackButtonClick,
  title,
  comment,
}: Props) => {
  const { t } = useTranslation();
  const [isConfigOpen, setIsConfigOpen] = useState<boolean>(false);

  return (
    <div data-is-chart-config-open={isConfigOpen.toString()} className='chart'>
      <div
        onClick={() => {
          setIsConfigOpen(!isConfigOpen);
        }}
        className='chart-config'
        title={isConfigOpen ? t('CloseConfig') : t('OpenConfig')}
      >
        <CustomSVGs svg={filterSVG} />
      </div>
      {isBackButtonVisible && (
        <div
          title={t('GoBackToPreviousChart')}
          onClick={onBackButtonClick}
          className='chart-goBack'
        >
          <CustomSVGs svg={arrowHeadLeftSVG} />
        </div>
      )}
      <div className='chart__left'>
        <div className='chart__left--content'>
          <div className='chart__left--content-title'>{title}</div>
          <div className='chart__left--content-comment'>{comment}</div>
          {/* Render Chart */}
          {children}
        </div>
      </div>
      <div className='chart__right'>
        <div className='chart__right--controls'>
          {groupByOptionsArr && groupBy && setGroupBy && (
            <div className='chart__right--controls-selections'>
              <div className='chart__right--controls-selections-title'>
                {t('GroupBy')}
              </div>
              <div className='chart__right--controls-selections-inputs'>
                <NormalSelectInput
                  defaultValue={groupBy}
                  setSelect={setGroupBy}
                  selectOptionArr={groupByOptionsArr}
                  //   placeholder={t('Time')}
                  id={'ConversionRateChart-groupBy'}
                />
              </div>
            </div>
          )}
          {timeFilter && setTimeFilter && timeFilterOptionsArr && (
            <div className='chart__right--controls-selections'>
              <div className='chart__right--controls-selections-title'>
                {t('Filters')}
              </div>
              <div className='chart__right--controls-selections-inputs'>
                <NormalSelectInput
                  defaultValue={timeFilter}
                  setSelect={setTimeFilter}
                  selectOptionArr={timeFilterOptionsArr}
                  placeholder={t('Time')}
                  id={'ConversionRateChart-timeFilter'}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChartWithConfig;
