import { useTranslation } from 'react-i18next';
import SimpleCard from '../../../ui/cards/SimpleCard';
import Lottie from 'lottie-react';
import '../../../styles/components/charts/pieChartComponent.scss';
import statsLottie from '../../../assets/json/lottie_animation/statsInfographicsLottie.json';
import setupTrackingLottie from '../../../assets/json/lottie_animation/setup_tracking.json';
import '../../../styles/pages/dashboard/stats/aiCheckoutTrackingStats.scss';
import MainDeviceBasedCRChart from '../../../components/charts/ConversionRate/DeviceBased/MainDeviceBasedCRChart';
import MainOverallAndLocationBasedCRChart from '../../../components/charts/ConversionRate/OverallAndLocationBased/MainOverallAndLocationBasedCRChart';
import WeeklyConversionRateLineChart from '../../../components/charts/ConversionRate/Weekly/WeeklyConversionRateLineChart';
import MainAverageCartValueChart from '../../../components/charts/AverageCartValue/MainAverageCartValueChart';

type Props = {
  isTrackable: boolean;
  isTimesUp: boolean;
};

const AiCheckoutTrackingStats = ({ isTrackable, isTimesUp }: Props) => {
  const { t } = useTranslation();

  const renderCharts = () => {
    return (
      <div className='dummyChart'>
        <div className='dummyChart--content'>
          {/* CR means Conversion Rate */}
          <MainOverallAndLocationBasedCRChart />
        </div>
        <div className='dummyChart--content'>
          <WeeklyConversionRateLineChart />
        </div>
        <div className='dummyChart--content'>
          {/* CR means Conversion Rate */}
          <MainDeviceBasedCRChart />
        </div>
        <div className='dummyChart--content'>
          <MainAverageCartValueChart />
        </div>
      </div>
    );
  };

  return (
    <div className='aiCheckoutTrackingStats--container'>
      <div className='aiCheckoutTrackingStats'>
        {isTrackable && !isTimesUp && (
          <SimpleCard grow={false}>
            <div className='aiCheckoutTrackingStats__card'>
              <h3>{t('TrendsText1')}</h3>
              <div className='aiCheckoutTrackingStats__card--lottie'>
                <Lottie animationData={statsLottie} loop={true} />
              </div>
            </div>
          </SimpleCard>
        )}
        {!isTrackable && !isTimesUp && (
          <SimpleCard grow={false}>
            <div className='aiCheckoutTrackingStats__card'>
              <h3>{t('TrendsText2')}</h3>
              <div className='aiCheckoutTrackingStats__card--lottie'>
                <Lottie animationData={setupTrackingLottie} loop={true} />
              </div>
            </div>
          </SimpleCard>
        )}
        {renderCharts()}
      </div>
    </div>
  );
};

export default AiCheckoutTrackingStats;
